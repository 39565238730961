body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar a {
  font-family: "BenchNine", sans-serif;
  font-weight: bold;
  font-size: 22px;
}
.pagination .page-item.active .page-link {
  background-color: #4285f4 !important;
}
.navbar .active {
  color: #075979 !important;
}
.navbar .--active {
  color: #900 !important;
}
.navbar a:hover {
  color: #075979 !important;
}
.btn-sinape-primary {
  background-color: #075979 !important;
}

.btn-sinape-secondary {
  background-color: #50cac3 !important;
}
.custom-btn-send {
  background-color: #075979 !important;
  border-radius: 50px !important;
  padding: 8px 20px !important;
  font-weight: bold !important;
}

.table td {
  background-color: #075979 !important;
  vertical-align: baseline !important;
  padding-bottom: 10px !important;
  padding-top: 15px !important;
  text-transform: uppercase;
  color: #fff;
}
.table th {
  font-weight: bold !important;
}

.cards-email-box {
  margin-bottom: 10px;
}
.cards-email-box .card-body {
  padding: 7px 10px;
  cursor: pointer;
}
.cards-email-box .card-body h6 {
  margin: 4px 10px 0 0;
  font-size: 14px;
  float: left;
  padding: 0;
}
.cards-email-box .card-body strong {
  color: #075979 !important;
}
.cards-email-box .card-body .btn {
  background-color: #cc0000 !important;
  padding: 3px 8px !important;
  margin: 0 !important;
  float: right;
}

.bg-app {
  background-color: #075979;
  margin-bottom: 110px;
  margin-top: 200px;
  max-height: 110px;
}
.bg-app .img-phone {
  margin-top: -160px;
  height: auto;
  width: 65%;
}
.bg-app .img-playstore {
  margin-top: 25px;
  height: auto;
  width: 65%;
}
.bg-app h3 {
  font-family: "BenchNine", sans-serif;
  margin: 38px 0 0 0;
  font-size: 1.7em;
  color: #fff;
  padding: 0;
}
.Title {
  text-align: center;
  padding: 100px 0 50px 0;
}
.custom-btn {
  border-radius: 50px !important;
  background-color: #3e5a8f !important;
  padding: 15px !important;
  height: 50px;
  width: 50px;
}
.Title h1 {
  font-family: "BenchNine", sans-serif;
  text-align: center;
  font-weight: bold;
}
.about {
  margin-top: 20px;
}
.slider {
  padding-top: 95px;
  max-height: 600px;
}
.carousel-control-prev,
.carousel-control-next {
  top: 95px !important;
}

.border_title {
  background-color: #075979 !important;
  margin: 0 auto;
  height: 4px;
  width: 80px;
}
.developer {
  margin: 120px auto;
  width: 23%;
}
.developer h6 {
  font-weight: bold;
  margin-top: 15px;
  float: left;
}
.developer img {
  float: left;
  width: 100px;
  margin: 0 10px;
}

.modal-dialog .modal-lg {
  max-width: 900px !important;
}
.form-control {
  border: solid 2px #50cac3 !important;
  color: #075979 !important;
  height: 42px !important;
}
.textarea {
  border: solid 2px #50cac3 !important;
  color: #075979 !important;
  height: 150px !important;
  border-radius: 5px;
  padding: 7px;
  width: 100%;
}
.copyright {
  font-weight: bold;
  color: #fff;
}
.btn-new {
  background-color: #075979 !important;
  border-radius: 50px !important;
  padding: 8px 20px !important;
  position: absolute;
  font-weight: bold;
  left: 45%;
  top: -22px;
}
.btn-back {
  background-color: #075979 !important;
  border-radius: 30px 50px 50px 0px !important;
  padding: 8px 20px !important;
  position: absolute;
  font-weight: bold;
  left: -6px;
  top: -30px;
}
.btn-new_bottom {
  background-color: #075979 !important;
  border-radius: 50px !important;
  padding: 8px 20px !important;
  position: absolute;
  font-weight: bold;
  left: 45%;
  bottom: -22px;
}
.card-title {
  border-bottom: solid 2px #075979;
  text-transform: uppercase;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 18px;
}
.semaphore_detail {
  border-top: dotted 0.1em #075979;
  padding-top: 30px;
}
.semaphore_detail h4 {
  font-weight: bold;
  font-size: 16px;
  margin-top: 3px;
  float: left;
  padding: 0;
}
.semaphore_detail h1 {
  border-bottom: solid 1px #50cac3;
  text-transform: uppercase;
  padding: 0 0px 5px 0;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  float: left;
}
.semaphore_detail p {
  font-weight: bold;
  color: #50cac3;
  margin-left: 5px;
  margin-top: 3px;
  font-size: 14px;
  float: left;
}
.semaphore_detail .item {
  margin: 5px 0;
  float: left;
  width: 100%;
}
.semaphore_detail .fa {
  margin: 0 10px 10px 0;
  font-size: 30px;
  float: left;
}

.semaphore_detail .grafic {
  margin-top: 40px;
  width: 100%;
  float: left;
}
.semaphore_detail .grafic h1 {
  border-radius: 10px 10px 0 0;
  background-color: #075979;
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
  padding: 7px;
  border: none;
}
.semaphore_detail .grafic h2 {
  background-color: #50cac3;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  padding: 7px;
  border: none;
  margin: 0;
}
.semaphore_detail .grafic_detail {
  border: dotted 1px #ccc;
  margin-bottom: 30px;
  padding: 5px;
}

.report_detail {
}
.report_detail .logo {
  width: 150px;
  margin: 0 auto;
}
.report_detail h1 {
  font-weight: bold;
  color: #075979;
  margin-top: 15px;
  font-size: 18px;
}
.report_detail p {
  margin: 5px 0;
}
.report_detail .info_system {
  text-align: center;
}
.report_detail .info_system h1 {
  background-color: #50cac3;
  color: #fff;
  padding: 7px;
}
.report_detail .info_system h4 {
  border-bottom: 1px solid #50cac3;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  margin-top: 20px;
  font-size: 16px;
  width: 100%;
  float: left;
}
.report_detail .info_system p {
  text-align: left;
  margin: 0px;
  float: left;
}

.report_detail .energy_quality {
  text-align: center;
}
.report_detail .energy_quality h1 {
  background-color: #075979;
  margin-bottom: 30px;
  color: #fff;
  padding: 7px;
}
.report_detail .energy_quality h4 {
  border-bottom: 1px solid #50cac3;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  margin-top: 20px;
  font-size: 16px;
  width: 100%;
  float: left;
}
.report_detail .energy_quality p {
  text-align: left;
  margin: 0px;
  float: left;
}

.report_detail .energy_generation {
  text-align: center;
}
.report_detail .energy_generation h1 {
  background-color: #075979;
  margin-bottom: 30px;
  margin-top: 50px;
  color: #fff;
  padding: 7px;
}
.report_detail .energy_generation h4 {
  border-bottom: 1px solid #50cac3;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  margin-top: 20px;
  font-size: 16px;
  width: 100%;
  float: left;
}
.report_detail .energy_generation p {
  text-align: left;
  margin: 0px;
  float: left;
}

.report_detail .report h1 {
  background-color: #50cac3;
  text-align: center;
  margin-top: 50px;
  color: #fff;
  padding: 7px;
}

.nav-item {
  position: relative;
}
.notification {
  background-color: #cc0000;
  padding: 1px 0px 0 7px;
  border-radius: 50px;
  position: absolute;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  height: 20px;
  width: 20px;
  right: 5px;
  top: -6px;
}
.home img {
  float: left !important;
  margin: 0px 5px 0 0;
}
.home .line {
  width: 100%;
  float: left;
}
.home .card-title {
  font-size: 19px !important;
}
.home h2 {
  text-transform: uppercase;
  border-bottom: solid 1px #075979;
  font-weight: bold;
  font-size: 19px;
}
.home h1 {
  border-bottom: solid 1px;
  font-weight: bold;
  margin-top: 10px;
  font-size: 16px;
}
.home .line p {
  float: left !important;
}
